.cx-widget .cx-common-container.cx-minimize .cx-buttons-window-control button.cx-button-minimize {
    display: none;
}
.cx-widget .cx-common-container .cx-titlebar {
    border: none;
}
.cx-widget .cx-common-container .cx-titlebar .cx-icon{
    display: none;
}
.cx-widget .cx-common-container .cx-titlebar .cx-title{
    display: none;
}
.cx-webchat .cx-body:focus {
    outline: none;
}
.cx-transcript:focus {
    outline: none;
}
.cx-webchat .cx-transcript {
    margin-top: 15px;
    font-size: .9em !important;
}
#eventTriger, #startEventTriger{
    display: none;
}
.cx-webchat div.cx-input-container .cx-message-input {
    font-size: .9em !important;
}
/* .cx-widget .cx-common-container.cx-minimize .cx-buttons-window-control button.cx-button-close {
    display: none;
} */
/* .cx-common-container .cx-titlebar{
    display: none;
} */
#__vconsole {
    z-index: 999999999;
}
#__vconsole.vc-toggle .vc-panel {
    z-index: 999999999;
}
#__vconsole .vc-toolbar, #__vconsole .vc-switch {
    z-index: 999999999;
}
#__vconsole .vc-switch {
    right: 0px !important;
    bottom: 20px !important;
}